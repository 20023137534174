import React, { useState } from "react";
import PropTypes from "prop-types";
import CONFIG from "../../config.json";
import { Modal } from "react-bootstrap";
import MD5 from "crypto-js/md5";

export const SafetyButton = (props) => {
  const [pin, setPin] = useState("");
  const [invalidPin, setInvalidPin] = useState(false);
  const [showModal, setShowModel] = useState(false);

  const handleInput = (event) => {
    setPin(event.target.value);
    setInvalidPin(false);
  };

  const checkPin = () => {
    if (MD5(pin) === CONFIG.CONFIRM_OPERATION_CODE) {
      props.clickCallback();

      setShowModel(false);
    } else {
      setInvalidPin(true);
    }
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#pinModal"
        onClick={() => setShowModel(true)}
      >
        {props.name}
      </button>
      <Modal show={showModal} onHide={() => setShowModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Pin modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-2">
              <label className="col-form-label">Pin:</label>
            </div>
            <div className="col-10">
              <input
                className={`form-control ${invalidPin ? "is-invalid" : ""}`}
                type="password"
                value={pin}
                onInput={handleInput}
              />
              <div className="invalid-feedback">
                Please provide a valid pin.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => setShowModel(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={checkPin}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

SafetyButton.propTypes = {
  name: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
};
